import React from "react";

const Process = () => {
  return (
    <>
      {/* mobile */}
      <div className="py-[2vh] flex flex-col lg:hidden">
        <div className="self-center text-white py-10 w-[60vw] md:w-[30vw]">
          <h2 className="text-xl font-black ">SCOUTING</h2>
          <p className="">
            Scouting ongoing
            <br /> (throughout the year)
          </p>
        </div>
        <span className="self-center text-2xl text-[#36B7FF] ">↓</span>

        <div className="self-center text-white py-10 w-[60vw] md:w-[30vw]">
          <h2 className="text-xl font-black ">SELECTION DAYS</h2>
          <p className="">Draft early event</p>
        </div>

        <span className="self-center text-2xl text-[#36B7FF] ">↓</span>
        <div className="self-center text-white py-10 w-[60vw] md:w-[30vw]">
          <h2 className="text-xl font-black ">DEVELOPMENT</h2>
          <p className="">Intensive program for selected talents</p>
        </div>
        <span className="self-center text-2xl text-[#36B7FF] ">↓</span>
        <div className="self-center text-white py-10 w-[60vw] md:w-[30vw]">
          <h2 className="text-xl font-black ">TRANSFER</h2>
          <p className="">Top performing players continue to play in top international clubs</p>
        </div>
      </div>

      {/* desktop */}

      <div className=" py-[10vh] hidden lg:flex lg:flex-row lg:justify-center">
        <div className="self-center text-white px-10 flex flex-col w-[20vw]">
          <div className=" ml-[2rem] w-12 h-12 bg-white text-black flex items-center justify-center rounded-full text-lg font-semibold mb-10">
            1
          </div>
          <h2 className="text-xl font-black ">SCOUTING</h2>
          <p className="">
            Scouting ongoing
            <br /> (throughout the year)
          </p>
        </div>
        <span className="self-center text-2xl text-[#36B7FF] ">→</span>

        <div className="self-center text-white px-10 flex flex-col w-[20vw]">
          <div className=" ml-[2rem] w-12 h-12 bg-white text-black flex items-center justify-center rounded-full text-lg font-semibold mb-10">
            2
          </div>
          <h2 className="text-xl font-black ">SELECTION DAYS</h2>
          <p className="">
            Draft early event <br /> <span className="text-[#073691]">.</span>
          </p>
        </div>

        <span className="self-center text-2xl text-[#36B7FF] ">→</span>
        <div className="self-center text-white px-10 flex flex-col w-[20vw]">
          <div className="ml-[2rem] w-12 h-12 bg-white text-black flex items-center justify-center rounded-full text-lg font-semibold mb-10 ">
            3
          </div>
          <h2 className="text-xl font-black ">DEVELOPMENT</h2>
          <p className="">Intensive program for selected talents</p>
        </div>
        <span className="self-center text-2xl text-[#36B7FF] ">→</span>
        <div className=" self-center text-white px-10 flex flex-col w-[20vw]">
          <div className="ml-[2rem] w-12 h-12 bg-white text-black flex items-center justify-center rounded-full text-lg font-semibold mb-10 ">
            4
          </div>
          <h2 className="text-xl font-black ">TRANSFER</h2>
          <p className="">Top performing players continue to play in top international clubs</p>
        </div>
      </div>
    </>
  );
};

export default Process;
