import React from "react";
import JourneyCard from "../components/JourneyCard";
import journey from "../content/journey";
import Container from "../hoc/Container";

const Journey = () => {
  return (
    <>
      <Container className="bg-gradient-to-br from-[#004AAD] to-[#091361]">
        <div className="text-center space-y-3">
          <h2 className="text-white text-4xl font-black">THE <span className="text-[#36B7FF]">J</span>OURNEY</h2>
          <p className="text-[#d9d9d9]">“Everything Starts as a Dream, but Requires Action”</p>
        </div>
        {journey.map(({ imgPath, title, description }, index) => (
          <JourneyCard key={title} imgPath={imgPath} title={title} description={description} index={index} />
        ))}
      </Container>
      {/* <img src="/services.png" className="w-full" alt="" /> */}
      <Container className="bg-gradient-to-bl from-[#091361] to-[#004AAD]">
        <div className="text-xl md:text-2xl font-bold text-[#d9d9d9] text-center space-y-3">
          <p>
            BELIEVE has a playbook to make <span className="text-white font-black">your football journey</span> a
            success.
          </p>
          <p>We want to be a part of your success story.</p>
        </div>
      </Container>
    </>
  );
};

export default Journey;
