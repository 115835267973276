import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";

const Main = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsAnimationComplete(true);
    }, 6300);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`z-10 h-24 transition-all duration-300 ${
          isFixed
            ? "fixed bg-white shadow-lg top-0 w-full py-4 px-5 xl:px-40 h-20 sm:h-24"
            : "absolute top-6 left-0 xl:left-40"
        }`}
      >
        <img
          src={`${isFixed ? "/logo-black.png" : "/logo-white.png"}`}
          className={` pl-8 transition-opacity duration-500 w-[4rem] mb-[2rem]
           ${isAnimationComplete ? "opacity-100" : "opacity-85 "}`}
          alt="Believe.football"
        />
      </header>
      <div className="h-screen bg-gradient-to-br w-full from-[#004AAD] to-[#091361] py-10 px-40">
        <div className="text-center text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full space-y-10 max-w-[1230px]">
          <TypeAnimation
            wrapper="h1"
            sequence={[100, "BELIE", 100, "BELIEVE...", 200, "BELIEVE"]}
            className=" text-[#36B7FF] text-4xl xs:text-[42px] md:text-7xl leading-tight font-black"
          />

          <h1 className="text-3xl xs:text-[42px] md:text-7xl">TALENT DEVELOPMENT ALGORITHM</h1>

          <TypeAnimation
            sequence={[
              1100,
              `BELIEVE HAD BUILD A LARGE SCALE
            EFFICIENT SCOUTING METHODOLOGY
            THAT AUTOMATES MOST OF THE INITIAL
            PROCESS`,
            ]}
            className=" pt-[6rem] text-xl md:text-2xl"
            wrapper="p"
          />
        </div>
      </div>
    </>
  );
};

export default Main;
