import Main from "./sections/Main";
import OurWork from "./sections/OurWork";
import Journey from "./sections/Journey";

import ContactUs from "./sections/ContactUs";

function App() {
  return (
    <>
      <Main />
      <OurWork />
      <Journey />
      <ContactUs />
    </>
  );
}

export default App;
