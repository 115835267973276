import React from "react";

const JourneyCard = ({ imgPath, title, description, index }) => {
  return (
    <div
      className={`md:flex items-center justify-between gap-x-16 ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"}`}
    >
      <div className=" object-cover" alt="">
        <h1 className="flex text-white text-2xl md:text-6xl font-black lg:mx-[10vw]">
          <span className="text-[#36B7FF]">{title[0]}</span> {title.slice(1)}
        </h1>
      </div>
      <div className="text-center md:text-left mt-5 md:mt-0 space-y-3 md:space-y-0">
        {/* <h3 className="text-white text-xl md:text-2xl font-black">{title}</h3> */}
        <p className=" pt-5 text-justify text-[#d9d9d9] text-base md:text-lg lg:w-[35vw]">{description}</p>
      </div>
    </div>
  );
};

export default JourneyCard;
