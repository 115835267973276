import React from "react";

const Container = ({ className = "", children }) => {
  return (
    <section className={`px-4 sm:px-10 xl:px-40 space-y-16 pt-24 pb-16 ${className}`}>
      {children}
    </section>
  );
};

export default Container;
