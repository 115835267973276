const journey = [
  {
    index: 1,
    imgPath: "/ux.png",
    title: "ACADEMY",
    description: `Within the heart of our endeavor lies an altruistic mission—a beacon of hope to those whose lives have been cloaked in adversity. These young souls, bereft of opportunities and confined to the clutches of a perilous existence, shall be offered an extraordinary chance: admission into the prestigious football academy. Over a designated span of time, they shall undergo rigorous training under the watchful eye of a dedicated team of coaches. Moreover, their newfound journey will be accompanied by an elevation in their standard of living—a realm where nourishing sustenance, comprehensive medical care, and the guidance of accomplished educators converge.
      `,
  },
  {
    index: 2,
    imgPath: "/des_and_dev.png",
    title: "COMMUNITY",
    description: `Our mission goes beyond sports as we strive to make a positive impact on the community. By fulfilling dreams and providing support to millions of children and families living in extreme poverty, we aim to create lasting change and opportunities for a better future.`,
  },
  {
    index: 3,
    imgPath: "/launch.png",
    title: "VISION",
    description: `At the core of our noble pursuit lies the goal of generating a lineage of extraordinary football players—adept in their craft, revered by the masses, and coveted by prestigious football clubs. A cascade of brilliance shall be unleashed, heralding the emergence of a new wave of talent from the depths of Ghana's fertile grounds. And with every prodigious player sold, their transformative fortunes shall be reinvested into financing this noble undertaking, enabling us to extend our reach and embrace a greater number of children each passing year.`,
  },
];

export default journey;
