import React from "react";
import Container from "../hoc/Container";

const ContactUs = () => {
  return (
    <Container className="bg-primary">
      <h2 className="text-white text-2xl font-black text-center">
        Contact Us at{" "}
        <a className="underline text-l" href="mailto:stas@believe.football">
        stas@believe.football
        </a>
      </h2>
      <p className="text-white text-[1rem] text-center">© 2024 BELIEVE</p>
    </Container>
  );
};
export default ContactUs;
