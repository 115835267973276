import React from "react";
import Process from "../components/process";

const OurWork = () => {
  return (
    <section className="bg-gradient-to-bl w-full from-[#091361] to-[#004AAD]">
      <h2 className="text-white text-3xl md:text-4xl font-black text-center pt-[5vh]">
        THE <span className="text-[#36B7FF]">P</span>ROCESS
      </h2>

      <Process />
    </section>
  );
};

export default OurWork;
